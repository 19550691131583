<template>
  <NuxtLink
    v-if="person"
    :to="{
      name: indexStore.currentPaperSlug ? 'paper_person' : 'person',
      params: {
        person: isPerson(person) ? person.UrlKey : person.urlKey,
        paper: indexStore.currentPaperSlug,
      },
    }"
    class="group block"
  >
    <div
      class="flex cursor-pointer z-10 relative"
      :class="
        isMm()
          ? ''
          : 'mb-3 md:mb-4 bg-white border border-gray-300 rounded-xl md:-ml-3 md:-mr-3 p-3'
      "
    >
      <NuxtImg
        v-if="imageUrl"
        class="rounded-full shrink-0 bg-black w-15 h-15 mr-3 overflow-hidden object-cover object-center"
        width="64"
        loading="lazy"
        :src="imageUrl"
      />
      <div>
        <h4 class="list-title-s mb-1">
          {{ isPerson(person) ? person.Name : person.name }}
        </h4>
        <div class="list-title-xxxs !font-medium">
          {{ isPerson(person) ? person.Title : person.title }}
        </div>
        <div class="body-xs text-gray-500">
          {{ isPerson(person) ? person.Education : person.education }}
        </div>
      </div>
      <div v-if="showContactButton" class="absolute right-0 pr-3">
        <a>
          <CommonButton
            class="cursor-pointer label-s"
            bg="bg-red"
            text="text-white"
          >
            Kontakt
          </CommonButton>
        </a>
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { Person } from '~/typesManual/content_api/article.js'
import type { PersonArticleDTO } from '~/typesAuto/apicore/v1'

const config = useRuntimeConfig()

const indexStore = useIndexStore()

function isPerson(object: Person | PersonArticleDTO): object is Person {
  return Boolean((object as Person)?.UrlKey)
}

function isPersonArticleDTO(
  object: Person | PersonArticleDTO
): object is PersonArticleDTO {
  return Boolean((object as PersonArticleDTO)?.urlKey)
}

const imageUrl = computed(() => {
  if (!props.person) {
    return ''
  }

  if (isPerson(props.person)) {
    if (props.person.MasterId && props.person.MasterId !== 0) {
      return `https://legacy.altinget.dk/images/person/${props.person.MasterId}/${props.person.Image}`
    }

    return `${config.public.site.legacydomain}/images/person/${props.person.RecordId}/${props.person.Image}`
  } else if (isPersonArticleDTO(props.person)) {
    if (props.person.masterId && props.person.masterId !== 0) {
      return `https://legacy.altinget.dk/images/person/${props.person.masterId}/${props.person.image}`
    }

    return `${config.public.site.legacydomain}/images/person/${props.person.id}/${props.person.image}`
  }

  return ''
})

const props = withDefaults(
  defineProps<{
    person?: PersonArticleDTO | Person
    showContactButton?: boolean
  }>(),
  {
    showContactButton: false,
  }
)
</script>
